import type { FC } from 'react';
import type { ComputedError, ErrorKey, Alert } from '../../model/Core';
import { prepareValue } from '../../utils/render';
import { AlertCell } from '../Common';
import styles from './styles.module.scss';

type ErrorSystemProps = {
  caption: string;
  errors: ComputedError;
};

const ErrorSystem: FC<ErrorSystemProps> = ({
  caption = '',
  errors = { values: {}, alerts: {} },
}) => {
  const { values, alerts } = errors;

  return (
    <table className={styles.table}>
      <caption>
        <span className={styles.summary}>{caption}</span>
      </caption>
      <tbody>
        {Object.entries(values).map(([header, value]) => (
          <tr key={header} className={styles.row}>
            <th className={`${styles.header} ${styles.cell}`}>{header}</th>
            <AlertCell className={styles.cell} alert={alerts[header as ErrorKey] as Alert}>
              {prepareValue(value)}
            </AlertCell>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ErrorSystem;
