import type { FC } from 'react';
import type { ComputedError } from '../../model/Core';
import { prepareValue } from '../../utils/render';
import { AlertCell } from '../Common';
import styles from './styles.module.scss';

type ErrorHintProps = {
  errors: ComputedError[];
};

const ErrorHint: FC<ErrorHintProps> = ({
  errors = [],
}) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th />
          {errors.map(({ name }) => (
            <th key={name} className={`${styles.header} ${styles.cell}`}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className={styles.row}>
          <th className={`${styles.header} ${styles.cell}`}>Setting-out</th>
          {errors.map(({ name, values }) => (
            <td key={name} className={styles.cell}>{prepareValue(values.lenPre)}</td>
          ))}
        </tr>
        <tr className={styles.row}>
          <th className={`${styles.header} ${styles.cell}`}>As-cast</th>
          {errors.map(({ name, values }) => (
            <td key={name} className={styles.cell}>{prepareValue(values.lenPost)}</td>
          ))}
        </tr>
        <tr className={styles.row}>
          <th className={`${styles.header} ${styles.cell}`}>Error</th>
          {errors.map(({ name, values, alerts }) => (
            <AlertCell key={name} alert={alerts.diff}>{prepareValue(values.diff)}</AlertCell>
          ))}
        </tr>
      </tbody>
   </table>
  );
};

export default ErrorHint;
