import type { Auth } from '../model/Auth';
import type { Project, Sequence } from '../model/Core';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getToken, refreshToken, clearToken } from '../infrastructure/repositories/api/Auth';
import {
  getTenants,
  getProjects,
  deleteProject,
  getSequences,
  getSegmentSequence,
  deleteSequence,
  getSegmentData,
} from '../infrastructure/repositories/api/Core';

// Auth
export const useAuth = () => useQueryClient().getQueryData<Auth>(['auth']) as Auth;

export const useSignin = (authCode: string, redirectUrl: string) => useQuery({
  queryKey: ['auth'],
  queryFn: () => getToken(authCode, redirectUrl),
  staleTime: Infinity,
  enabled: !!authCode && !!redirectUrl,
  retry: false,
});

export const useRefreshToken = () => useQuery({
  queryKey: ['auth'],
  queryFn: refreshToken,
  staleTime: Infinity,
  retry: false,
});

export const useSignout = () => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['logout'],
    queryFn: () => clearToken(accessToken),
    enabled: false,
  });
};

// Core
export const useTenants = () => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['tenants'],
    queryFn: () => getTenants(accessToken),
    retry: false,
  });
};

export const useProjects = (tenantId?: string) => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['projects', tenantId],
    queryFn: () => getProjects(accessToken, tenantId),
  });
};

export const useDeleteProject = (tenantId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation({
    mutationFn: (projectId: string) => deleteProject(accessToken, projectId),
    onSuccess: ({ id: projectId }) => {
      queryClient.setQueryData(['projects', tenantId], (old: Project[]) => {
        return old.filter((project) => project.id !== projectId);
      })
    },
  });
};

export const useSequences = (projectId: string) => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getSequences(accessToken, projectId),
  });
};

export const useDeleteSequence = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation({
    mutationFn: ({ projectId, sequenceId }: { projectId: string; sequenceId: string; }) =>
      deleteSequence(accessToken, projectId, sequenceId),
    onSuccess: ({ id: sequenceId, projectId }) => {
      queryClient.setQueryData(['project', projectId], (old: Record<string, Sequence>) => {
        const { [sequenceId]: _, ...sequences } = old;
        return sequences;
      })
    },
  });
};

export const useSegmentSequence = (projectId: string, segmentId: string) => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['sequence', segmentId],
    queryFn: () => getSegmentSequence(accessToken, projectId, segmentId),
  });
};

export const useSegmentSettings = (projectId: string, segmentId: string) => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['sequence', segmentId],
    queryFn: () => getSegmentSequence(accessToken, projectId, segmentId),
    select: (data) => data.settings,
  });
};

export const useSegment = (projectId: string, segmentId: string) => {
  const { accessToken } = useAuth();

  return useQuery({
    queryKey: ['segment', segmentId],
    queryFn: () => getSegmentData(accessToken, projectId, segmentId),
  });
};
