import { Vector3 } from 'three';

export const subAbs = (p1, p2) => {
  const diff = new Vector3().subVectors(p1, p2);

  return new Vector3(
    Math.abs(diff.x),
    Math.abs(diff.y),
    Math.abs(diff.z),
  );
};

export const asinLtd = (val) => {
  return (val >= 1 || val <= -1)
    ? Math.PI
    : Math.asin(val);
}

export const acosLtd = (val) => {
  if (val >= 1) return 0;
  if (val <= -1) return Math.PI;

  return Math.acos(val);
}

export const toRel = (point, sys, origin = null) => {
  const dirX = new Vector3().crossVectors(sys.y, sys.z);
  const relPos = new Vector3().subVectors(point, origin || sys.x);

  return new Vector3(
    relPos.clone().dot(dirX),
    relPos.clone().dot(sys.y),
    relPos.clone().dot(sys.z)
  );
};

export const sysToRel = (sysGlo, sys) => {
  const origin = new Vector3(0, 0, 0);
  const dirX = new Vector3().crossVectors(sys.y, sys.z);

  return {
    x: toRel(sysGlo.x, new Vector3(dirX, sys.y, sys.z), sys.x),
    y: toRel(sysGlo.y, new Vector3(dirX, sys.y, sys.z), origin),
    z: toRel(sysGlo.z, new Vector3(dirX, sys.y, sys.z), origin),
  };
};

export const toGlob = (point, sys) => {
  const dirX = new Vector3().crossVectors(sys.y, sys.z);

  return sys.x.clone()
    .addScaledVector(dirX, point.x)
    .addScaledVector(sys.y, point.y)
    .addScaledVector(sys.z, point.z);
};
