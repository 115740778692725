import type { FC } from 'react';
import styles from './styles.module.scss';

type LoaderProps = {
  title?: string;
};

const Loader: FC<LoaderProps> = ({
  title = 'Loading',
}) => {
  return (
    <div className={styles.loader}>
      <svg className={styles.spinner} aria-label={title} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect className={styles.animation} x="1" y="1" rx="1" width="10" height="10"/>
        <rect className={`${styles.animation} ${styles.delay1}`} x="1" y="1" rx="1" width="10" height="10"/>
        <rect className={`${styles.animation} ${styles.delay2}`} x="1" y="1" rx="1" width="10" height="10"/>
      </svg>
    </div>
  );
};

export default Loader;
