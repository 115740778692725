import type { FC, ReactNode } from 'react';
import type { Alert } from '../../../model/Core';
import classnames from 'classnames';
import styles from './styles.module.scss';

type AlertCellProps = {
  children: ReactNode;
  className?: string;
  alert: Alert;
};

const AlertCell: FC<AlertCellProps> = ({
  children,
  className = '',
  alert,
}) => (
  <td className={classnames(styles.tableCell, {
    [className]: Boolean(className),
    [styles.warning]: alert.isWarning,
    [styles.error]: alert.isError,
    [styles.highest]: alert.isHighest,
  })}>
    {children}
  </td>
);

export default AlertCell;
