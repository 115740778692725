import { Vector3 } from 'three';
import { SEG_TYPE } from '../../config';
import { toGlob, toRel, subAbs, asinLtd } from '../maths';
import { getStdAlerts, getDistMeanMax, getAlert } from './utils';

export const computeJointError = ({ pre, post }) => {
  const displace = subAbs(post.x, pre.x);
  const alfPre = asinLtd(pre.y.clone().dot(new Vector3(0, 0, 1))) * 180.0 / Math.PI;
  const betPre = asinLtd(pre.z.clone().dot(new Vector3(1, 0, 0))) * 180.0 / Math.PI;
  const gamPre = asinLtd(pre.y.clone().dot(new Vector3(1, 0, 0))) * 180.0 / Math.PI;
  const alfPost = asinLtd(post.y.clone().dot(new Vector3(0, 0, 1))) * 180.0 / Math.PI;
  const betPost = asinLtd(post.z.clone().dot(new Vector3(1, 0, 0))) * 180.0 / Math.PI;
  const gamPost = asinLtd(post.y.clone().dot(new Vector3(1, 0, 0))) * 180.0 / Math.PI;

  return {
    values: {
      degAlf: alfPost - alfPre,
      degBet: betPost - betPre,
      degGam: gamPost - gamPre,
      dx: displace.x,
      dy: displace.y,
      dz: displace.z,
    },
  };
};

export const computeHintErrors = (sysSt, sysEn, sysRef, hints) => {
  return [{
    name: 'Center length',
    values: {
      lenPre: sysRef.pre.x.length(),
      lenPost: sysRef.post.x.length(),
      diff: sysRef.post.x.length() - sysRef.pre.x.length(),
    },
  }].concat(hints.map((hint) => {
    const lenPre = toGlob(new Vector3(0, hint.start.x, hint.start.y), sysSt.pre)
      .distanceTo(toGlob(new Vector3(0, hint.end.x, hint.end.y), sysEn.pre));
    const lenPost = toGlob(new Vector3(0, hint.start.x, hint.start.y), sysSt.post)
      .distanceTo(toGlob(new Vector3(0, hint.end.x, hint.end.y), sysEn.post));

    return {
      name: `${hint.name} length`,
      values: {
        lenPre: lenPre,
        lenPost: lenPost,
        diff: lenPost - lenPre,
      },
    };
  }));
};

export const computeSettingErrors = (segType, sysSt, sysEn, cps) => {
  return cps.map((cp) => {
    const sysRef = (cp.atJoint === (segType === SEG_TYPE.castUp))
      ? sysSt
      : sysEn;

    const posPre = toRel(cp.pre, sysRef.pre);
    const posPost = toRel(cp.post, sysRef.post);

    const discr = posPost.clone().sub(posPre);

    return {
      name: cp.name,
      values: {
        dist: discr.length(),
        x: discr.x,
        y: discr.y,
        z: discr.z,
      },
    };
  });
};

export const computeCast = (segment, settings): {
  info: {
    mean: number;
    max: number;
    abs: number;
  };
  jointErrors: ComputedError;
  hintErrors: ComputedError[];
  settingErrors: ComputedError[];
} => {
  const {
    type,
    controlPoints: cps,
    systemWidgets,
    topoHints: hints,
  } = segment;

  const sysSt = systemWidgets.find(({ isStart }) => isStart);
  const sysEn = systemWidgets.find(({ isStart }) => !isStart);
  const sys = type === SEG_TYPE.castUp ? {
    ...sysSt,
    pre: {
      ...sysSt.pre,
      y: sysSt.pre.y.clone().negate(),
    },
    post: {
      ...sysSt.post,
      y: sysSt.post.y.clone().negate(),
    },
  } : sysEn;

  const jointErrors = computeJointError(sys);
  const jointAlerts = getStdAlerts([jointErrors], settings.valOI, settings.factorWarn);

  const settingErrors = cps && computeSettingErrors(type, sysSt, sysEn, cps);
  const settingAlerts = cps && getStdAlerts(settingErrors, settings.valOI, settings.factorWarn, 'do');

  const hintErrors = hints && computeHintErrors(sysSt, sysEn, sys, hints);
  const hintAlerts = hints && hintErrors.map(({ name, values }) => ({
    name,
    values,
    alerts: {
      diff: getAlert(
        values.diff,
        hintErrors.map(({ values }) => values.diff),
        settings.valOI.dl,
        settings.factorWarn,
      ),
    },
  }));

  return {
    info: {
      ...(settingErrors ? getDistMeanMax(settingErrors) : {}),
      abs: new Vector3(
        jointErrors.values.dx,
        jointErrors.values.dy,
        jointErrors.values.dz,
      ).length(),
    },
    settingErrors: settingErrors && settingErrors.map((error, i) => ({
      ...error,
      alerts: settingAlerts[i],
    })),
    jointErrors: { values: jointErrors.values, alerts: jointAlerts[0] },
    hintErrors: hintAlerts,
  };
};
