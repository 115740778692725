import type {
  Tenant,
  Project,
  Sequence,
  Report,
  ReportEntry,
  SegmentData,
  SystemWidget,
  TopoHint,
  Point,
} from '../../../model/Core';
import { Vector2, Vector3 } from 'three';

type TenantDto = {
  id: string;
  license: string;
  name: string;
};

type ProjectDto = {
  id: string;
  license: string;
  bridge: string;
  date: string;
};

type SequenceDto = {
  id: number;
  projectId: number;
  date: string;
  name: string;
  segments: SegmentHeaderDto[];
  report: ReportDto & {
    entries: ReportEntryDto[];
  };
  settings: {
    factorWarn: number;
    unit: number;
    valOI: {
      degAlf: number;
      degBet: number;
      degGam: number;
      dx: number;
      dy: number;
      dz: number;
      dl: number;
      do: number;
    };
    discrMould: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
    rigidMould: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
    discrSecConj: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
    rigidSecConj: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
    baseMatch: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
    globalPos: {
      x: number;
      y: number;
      z: number;
      dist: number;
    };
  },
  dump: string; // XML
};

type ReportDto = {
  id: string;
  date: number; // ISO
  temperature: number;
  humidity: number;
  pressure: number;
  comment: string;
};

type ReportEntryDto = {
  id: string;
  type: 'CAST' | 'SURVEY';
  name: string;
  leader: string;
  comment: string;
};

type PointDto = {
  id: string;
  name: string,
  type: string,
  atJoint: boolean;
  pre: number[], // [ x, y, z ]
  post: number[], // [ x, y, z ]
};

type SystemWidgetDto = {
  id: string;
  segmentId: string;
  segmentType: string;
  isStart: boolean;
  pre: number[][]; // [ [ x, y, z ], [ x, y, z ], [ x, y, z ] ]
  post: number[][]; // [ [ x, y, z ], [ x, y, z ], [ x, y, z ] ]
};

type TopoHintDto = {
  id: string;
  name: string;
  start: number[]; // [ y, z ]
  end: number[]; // [ y, z ]
};

type OutlineDto = {
  id: string;
  start: number;
  end: number;
};

type OutlineSlabDto = {
  id: string;
  start: number[][]; // [ x, y, z ]
  end: number[][]; // [ x, y, z ]
};

type LineDto = {
  id: string;
  pre: number[][]; // [ x, y, z ]
  post: number[][]; // [ x, y, z ]
};

type SegmentHeaderDto = {
  id: string;
  name: string;
  type: string;
};

type SegmentDataDto = SegmentHeaderDto & {
  controlPoints: PointDto[];
  systemWidgets: SystemWidgetDto[];
  topoHints: TopoHintDto[];
  outlines: OutlineDto & {
    slabs: OutlineSlabDto[];
    lines: LineDto[];
  }[];
};

// Interface if needed
const toTenant = (tenant: TenantDto): Tenant => tenant;
const toProject = (project: ProjectDto): Project => project; // Implicit interface
const toReport = (report: ReportDto): Report => ({
  id: report.id,
  date: report.date,
  temperature: report.temperature,
  humidity: report.humidity,
  pressure: report.pressure,
  comment: report.comment,
});
const toReportEntry = (reportEntry: ReportEntryDto): ReportEntry => reportEntry; // Implicit interface
const toSequence = (sequence: SequenceDto): Sequence => ({
  ...sequence,
  report: toReport(sequence.report),
  reportEntries: sequence.report.entries.reduce((data, entry) => ({
    ...data,
    [entry.id]: toReportEntry(entry),
  }), {}),
});
const toControlPoint = (point: PointDto): Point => ({
  id: point.id,
  name: point.name,
  type: point.type,
  atJoint: point.atJoint,
  pre: new Vector3().fromArray(point.pre),
  post: new Vector3().fromArray(point.post),
});

const toSystemWidget = (system: SystemWidgetDto): SystemWidget => ({
  id: system.id,
  segmentId: system.segmentId,
  isStart: system.isStart,
  segmentType: system.segmentType,
  pre: {
    x: new Vector3().fromArray(system.pre[0]),
    y: new Vector3().fromArray(system.pre[1]),
    z: new Vector3().fromArray(system.pre[2]),
  },
  post: {
    x: new Vector3().fromArray(system.post[0]),
    y: new Vector3().fromArray(system.post[1]),
    z: new Vector3().fromArray(system.post[2]),
  },
});

const toTopoHint = (hint: TopoHintDto): TopoHint => ({
  ...hint,
  start: new Vector2().fromArray(hint.start),
  end: new Vector2().fromArray(hint.end),
});

const toSegmentData = (segment: SegmentDataDto): SegmentData => ({
  ...segment,
  controlPoints: segment.controlPoints && segment.controlPoints.map(toControlPoint),
  systemWidgets: segment.systemWidgets && segment.systemWidgets.map(toSystemWidget),
  topoHints: segment.topoHints && segment.topoHints?.map(toTopoHint),
});

const CoreRepository = {
  async getTenants(accessToken: string): Promise<Record<string, Tenant>> {
    return fetch(`/api/tenant`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((tenants) => tenants.reduce((data: Record<string, Tenant>, tenant: TenantDto) => ({
        ...data,
        [tenant.id]: toTenant(tenant),
      }), {}));
  },

  async getProjects(accessToken: string, tenantId?: string): Promise<Project[]> {
    return fetch(`/api/project${tenantId ? `?tenantId=${tenantId}` : ''}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((projects) => projects.map(toProject));
  },

  async deleteProject(accessToken: string, projectId: string): Promise<Project> {
    return fetch(`/api/project/${projectId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((project) => toProject(project));
  },

  async getSequences(accessToken: string, projectId: string): Promise<Sequence[]> {
    return fetch(`/api/project/${projectId}/sequence`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((sequences) => sequences.reduce((data: Record<string, Sequence>, sequence: SequenceDto) => ({
        ...data,
        [sequence.id]: toSequence(sequence),
      }), {}));
  },

  async getSegmentSequence(accessToken: string, projectId: string, segmentId: string): Promise<Sequence> {
    return fetch(`/api/project/${projectId}/segment/${segmentId}/sequence`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((sequence) => toSequence(sequence));
  },

  async deleteSequence(accessToken: string, projectId: string, sequenceId: string): Promise<Sequence> {
    return fetch(`/api/project/${projectId}/sequence/${sequenceId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((sequence) => toSequence(sequence));
  },

  async getSegmentData(accessToken: string, projectId: string, segmentId: string): Promise<SegmentData> {
    return fetch(`/api/project/${projectId}/segment/${segmentId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
    .then((segment) => toSegmentData(segment));
  },

  async getPoints(accessToken: string, tenantId: string, projectId: string): Promise<PointDto[]> {
    return fetch(`/api/project/${projectId}?tenantId=${tenantId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then((points) => points);
  },
};

export const {
  getTenants,
  getProjects,
  deleteProject,
  getSequences,
  getSegmentSequence,
  deleteSequence,
  getSegmentData,
  getPoints,
} = CoreRepository;

export default CoreRepository;
