export const STORAGE = {
  featureFlags: 'ABES_flags',
};

export const SEG_TYPE = {
  mould: 'Mould',
  bulkHead: 'SecBH',
  conj: 'Conj',
  castUp: 'CastUp',
  castDown: 'CastDown',
};

export const FLAGS = {
  darkMode: 'darkMode',
};
