import { useSearchParams } from 'react-router-dom';
import { useSignin } from '../../hooks/queries';
import Loader from '../../components/Loader';

const Login = () => {
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code') || '';
  const redirectUrl = `${window.location.origin}/login`;

  const { isPending } = useSignin(authCode, redirectUrl);

  if (!isPending && !authCode) {
    const params = new URLSearchParams({
      client_id: '1i7ogsbldvalmco7sgq2bvk4tr',
      redirect_uri: redirectUrl,
      response_type: 'code',
    });

    window.location.href = `https://abes-cloud.auth.eu-west-2.amazoncognito.com/login?${params.toString()}`;
  }

  return (
    <Loader title="Signing you in. Please wait" />
  );
};

export default Login;
