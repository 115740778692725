import { useParams } from 'react-router-dom';
import { useSequences } from '../../hooks/queries';
import Loader from '../../components/Loader';
import SequenceList from '../../components/SequenceList';

const SequencesPage = () => {
  const { projectId } = useParams();
  const { isPending, error, data: sequences } = useSequences(projectId as string);

  if(isPending) return <Loader />;
  if(error) return <div>{error.message}</div>;

  return (
    <SequenceList
      sequences={Object.values(sequences)}
    />
  );
};

export default SequencesPage;
