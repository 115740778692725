type Props = {
  title?: string;
  reference: string;
  ariaHidden?: boolean;
  className?: string;
}

const SvgImage = ({
  title,
  reference,
  ariaHidden,
  className = '',
}: Props) => (
  <svg aria-hidden={ariaHidden} className={className}>
    {title && (
      <title>{title}</title>
    )}
    <use href={`/assets/sprite.svg#${reference}`} />
  </svg>
);

export default SvgImage;
