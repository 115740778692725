import { SEG_TYPE } from '../../config';
import { toGlob } from '../maths';

export const getSysWidgets = ({ id, type, systemWidgets: widgets }) => {
  if ([SEG_TYPE.castUp, SEG_TYPE.castDown].includes(type)) {
    return widgets.filter((widget) => widget.segmentId === id);
  } else if (type === SEG_TYPE.mould) {
    return widgets.filter((widget) => widget.segmentId === id)[0];
  }

  // Conj & SecBH segments remaining
  const refWidgets = widgets.filter(({ segmentType }) => [SEG_TYPE.castUp, SEG_TYPE.castDown].includes(segmentType));

  if (refWidgets.every(({ segmentType }) => segmentType === SEG_TYPE.castUp)) {
    return refWidgets
      .filter(({ isStart }) => isStart)
      .map(({ pre, post, ...rest }) => ({
        ...rest,
        pre: {
          ...pre,
          y: pre.y.clone().negate(),
        },
        post: {
          ...post,
          y: post.y.clone().negate(),
        },
      }))[0];
  } else {
    return refWidgets.filter(({ isStart }) => !isStart)[0];
  }
};

// Alerts
export const isWarning = (val, setting, factor) =>
  Math.abs(val) > setting * factor;

export const isError = (val, setting) =>
  Math.abs(val) > setting;

export const isHighest = (val, list) =>
  Math.abs(val) > Math.max(...list.map(Math.abs)) * .95;

export const getAlert = (val, list, setting, factor) => ({
  isWarning: !isError(val, setting) && isWarning(val, setting, factor),
  isError: isError(val, setting),
  isHighest: isHighest(val, list),
});

export const getStdAlerts = (errors, settings, factorWarn, settingsKey = '') => errors.map(({ values }) =>
  Object.entries(values).reduce((alerts, [key, value]) => ({
    ...alerts,
    [key]: getAlert(
      value,
      errors.map(({ values }) => values.dist),
      settings[settingsKey || key],
      factorWarn,
    ),
  }), {}));

// General
export const computeDiscr = (pre, post) => post.clone().sub(pre);
export const computeMove = (cp, sys) => toGlob(cp, sys).sub(cp);
export const getDistMeanMax = (errors) => ({
  mean: errors.reduce((sum, { values }) => sum + values.dist, 0) / errors.length,
  max: Math.max(...errors.map(({ values }) => Math.abs(values.dist))),
});
