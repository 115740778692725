import type { FC } from 'react';
import type { Project } from '../../model/Core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useSequences } from '../../hooks/queries';
import { SvgImage, Dropdown } from '../Common';
import { toDateString } from '../../utils/date';
import styles from './styles.module.scss';

type ProjectCardProps = Project & {
  selected?: boolean;
  deletable: boolean;
  onDelete?: (projectId: string) => void;
};

const ProjectCard: FC<ProjectCardProps> = ({
  id,
  license,
  bridge,
  date,
  selected,
  deletable,
  onDelete,
}) => {
  const { data: sequences } = useSequences(id);
  const dumps = sequences && Object.values(sequences).map(({ dump }) => dump);

  const xmlUrl = dumps?.length && URL.createObjectURL(new Blob([dumps.join('\n')], { type: 'text/xml' }));

  const classes = classnames(styles.projectCard, {
    [styles.selected]: selected,
  });

  return (
    <div className={styles.container}>
      <Link className={classes} to={`/project/${id}`}>
        <div className={styles.title}>
          <div className={styles.license}>{license}</div>
          {bridge}
        </div>

        <div className={styles.date}>{toDateString(date)}</div>
      </Link>
      <Dropdown
        className={styles.actions}
        label={<SvgImage className={styles.actionsLabel} reference="more" />}
      >
        {xmlUrl && (
          <a className={styles.download} download={`${license}-${bridge}.xml`} href={xmlUrl}>
            <SvgImage reference="download" />  Download
          </a>
        )}
        {deletable && onDelete && (
          <button className={styles.delete} onClick={() => onDelete(id)}>
            <SvgImage reference="delete" />  Delete
          </button>
        )}
      </Dropdown>
    </div>
  );
};

export default ProjectCard;
