import { useParams } from 'react-router-dom';
import { useSegmentSettings, useSegment } from '../../hooks/queries';
import { SEG_TYPE } from '../../config';
import { getSysWidgets } from '../../utils/Core/utils';
import { computeMould, computeConj, computeCast } from '../../utils/Core';
import { prepareValue, getUnitLabel } from '../../utils/render';
import { Overflow } from '../../components/Common';
import SegmentTitle from '../../components/SegmentTitle';
import ErrorStandard from '../../components/ErrorStandard';
import ErrorSystem from '../../components/ErrorSystem';
import ErrorHint from '../../components/ErrorHint';
import Loader from '../../components/Loader';

const SegmentPage = () => {
  const { projectId, segmentId } = useParams();
  const {
    isPending: segmentPending,
    error: segmentError,
    data: segment,
  } = useSegment(projectId, segmentId);
  const {
    isPending: sequencePending,
    error: sequenceError,
    data: settings,
  } = useSegmentSettings(projectId, segmentId);

  if (segmentPending || sequencePending) return <Loader />;
  if (segmentError) return <div>{segmentError.message}</div>;
  if (sequenceError) return <div>{sequenceError.message}</div>;

  const { controlPoints, systemWidgets } = segment;
  const unit = getUnitLabel(settings.unit);

  if (segment.type === SEG_TYPE.mould) {
    const {
      info,
      discr,
      move,
    } = computeMould(controlPoints, systemWidgets[0], settings);

    const discrCaption = `Surv. discrep. error: Max ${prepareValue(info.max)}[${unit}], Mean ${prepareValue(info.mean)}[${unit}]`;
    const moveCaption = `Rigid body error: Max ${prepareValue(info.abs)}[${unit}], Phi max ${prepareValue(info.phiMax)}[deg]`;

    return (
      <>
        <SegmentTitle segment={segment} />
        <ErrorStandard caption={discrCaption} errors={discr} />
        <ErrorStandard caption={moveCaption} errors={move} />
      </>
    );
  }

  if ([SEG_TYPE.bulkHead, SEG_TYPE.conj].includes(segment.type)) {
    const { discr, move, info } = computeConj(controlPoints, getSysWidgets(segment), settings);

    const discrCaption = `Surv. discrep. error: Max ${prepareValue(info.max)}[${unit}], Mean ${prepareValue(info.mean)}[${unit}]`;
    const moveCaption = `Rigid body error: Max ${prepareValue(info.abs)}[${unit}], Phi max ${prepareValue(info.phiMax)}[deg]`;

    return (
      <>
        <SegmentTitle segment={segment} />
        <Overflow>
          <ErrorStandard caption={discrCaption} errors={discr} />
        </Overflow>
        <Overflow>
          <ErrorStandard caption={moveCaption} errors={move} />
        </Overflow>
      </>
    );
  }

  if ([SEG_TYPE.castUp, SEG_TYPE.castDown].includes(segment.type)) {
    const {
      info,
      jointErrors,
      hintErrors,
      settingErrors,
    } = computeCast({
      ...segment,
      systemWidgets: getSysWidgets(segment),
    }, settings);

    const jointCaption = `Joint error: Abs ${prepareValue(info.abs)}[${unit}]`;
    const settingCaption = settingErrors && `Setting error: Max ${prepareValue(info.max)}[${unit}], Mean ${prepareValue(info.mean)}[${unit}]`;

    return (
      <>
        <SegmentTitle segment={segment} />
        <ErrorSystem caption={jointCaption} errors={jointErrors} />
        {hintErrors && (
          <ErrorHint errors={hintErrors} />
        )}
        {settingCaption && (
          <ErrorStandard caption={settingCaption} errors={settingErrors} />
        )}
      </>
    );
  }

  return (
    <div>An error has occured, please reload the page</div>
  );
};

export default SegmentPage;
