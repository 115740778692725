import type { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

type OverflowProps = {
  children: ReactNode;
};

const Overflow: FC<OverflowProps> = ({
  children,
}) => (
  <div className={styles.container}>
    {children}
  </div>
);

export default Overflow;
