import type { FC } from 'react';
import { useSignout } from '../../hooks/queries';

type LogoutButtonProps = {
  className: string;
  children: React.ReactNode;
};

const LogoutButton: FC<LogoutButtonProps> = ({
  className,
  children,
}) => {
  const { refetch } = useSignout();

  const handleLogout = () => refetch()
    .then(() => window.location.reload());

  return (
    <button
      className={className}
      onClick={handleLogout}
    >
      {children}
    </button>
  );
};

export default LogoutButton;
