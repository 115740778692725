import type { FC } from 'react';
import type { ComputedError } from '../../model/Core';
import { prepareValue } from '../../utils/render';
import { AlertCell } from '../Common';
import styles from './styles.module.scss';

type ErrorStandardProps = {
  caption: string;
  errors: ComputedError[];
};

const ErrorStandard: FC<ErrorStandardProps> = ({
  caption = '',
  errors = [],
}) => {
  const headers = [
    'Name',
    'x',
    'y',
    'z',
    'Dist',
  ];

  return (
    <table className={styles.table}>
      <caption>
        <span className={styles.summary}>{caption}</span>
      </caption>
      <thead>
        <tr className={styles.header}>
          {headers.map((header) => (
            <th key={header} className={styles.cell}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {errors.map(({ name, values, alerts }) => (
          <tr key={name} className={styles.row}>
            <td className={styles.cell}>{name}</td>
            <AlertCell alert={alerts.x}>{prepareValue(values.x)}</AlertCell>
            <AlertCell alert={alerts.y}>{prepareValue(values.y)}</AlertCell>
            <AlertCell alert={alerts.z}>{prepareValue(values.z)}</AlertCell>
            <AlertCell alert={alerts.dist}>{prepareValue(values.dist)}</AlertCell>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ErrorStandard;
