import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { FLAGS } from '../../config';
import styles from './styles.module.scss';

const ToggleTheme: FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem(FLAGS.darkMode) === 'true');

  const toggle = () => {
    if (isDarkMode) {
      setIsDarkMode(false);
    } else {
      setIsDarkMode(true);
    }
  };

  useEffect(() => {
    localStorage.setItem(FLAGS.darkMode, isDarkMode.toString());

    if (isDarkMode) {
      document.documentElement.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  return (
    <button className={styles.toggle} aria-pressed={isDarkMode} onClick={toggle}>
      <span className={styles.toggleKnob} aria-label="Dark mode"></span>
    </button>
  );
};

export default ToggleTheme;
