import type { FC } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
}

type SelectProps = {
  label: string;
  options: Option[];
  defaultOption?: Option;
  className?: string;
  onChange(value: string): void;
};

const Select: FC<SelectProps> = ({
  label,
  options,
  defaultOption,
  className = '',
  onChange,
}) => {
  return (
    <label
      className={classnames(styles.select, {
        [className]: !!className,
      })}
    >
      <span className={styles.label}>{label}</span>
      <span className={styles.elementContainer}>
        <select
          className={styles.element}
          onChange={(event) => onChange(event.target.value)}
        >
          {defaultOption && (
            <option value={defaultOption.value} disabled={defaultOption.disabled}>
              {defaultOption.label}
            </option>
          )}

          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </span>
    </label>
  );
};

export default Select;
