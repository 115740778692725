import { STORAGE } from './config';

const initialState = {
  darkMode: false,
};

export type Flags = typeof initialState;

export const initFeatureFlags = (): void => {
  const getLocalState = () =>
    JSON.parse(localStorage.getItem(STORAGE.featureFlags));

  const setLocalState = (state: Flags) =>
    localStorage.setItem(STORAGE.featureFlags, JSON.stringify(state));

  const toggleFlag = (flag: string): Flags => {
    const localState = getLocalState();

    if (!(flag in localState)) {
      console.error(`Failed to togle flag. Flag '${flag}' does not exist`);
      return;
    }

    const newState = {
      ...localState,
      [flag]: !localState[flag],
    };

    setLocalState(newState);

    const flagChangeEvent = new CustomEvent('flagChange', { flags: newState });
    window.dispatchEvent(flagChangeEvent);

    return newState;
  };

  const listFlags = (): void => {
    const flags = getLocalState();
    console.table(flags);
  };

  window.features = {
    toggleFlag,
    listFlags,
  };

  setLocalState({
    ...initialState,
    ...getLocalState(),
  });
};
