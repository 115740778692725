import type { FC } from 'react';
import type { Project } from '../../model/Core';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import ProjectCard from '../ProjectCard';
import styles from './styles.module.scss';

type ProjectListProps = {
  projects: Project[];
  className?: string;
  itemClassName?: string;
  deletable: boolean;
  onDelete?: (projectId: string) => void;
};

const ProjectList: FC<ProjectListProps> = ({
  projects,
  className = '',
  itemClassName = '',
  deletable = false,
  onDelete,
}) => {
  const { projectId } = useParams();

  if (!Boolean(projects.length)) {
    return <div className={styles.empty}>No projects found</div>
  }

  return (
    <ul className={className}>
      {projects.map((project) => (
        <li
          key={project.id}
          className={classnames(styles.project, {
            [itemClassName]: !!itemClassName,
          })}
        >
          <ProjectCard
            {...project}
            selected={projectId === project.id}
            deletable={deletable}
            onDelete={onDelete}
          />
        </li>
      ))}
    </ul>
  );
};

export default ProjectList;
