import type { FC } from 'react';
import type { DataSet, Tenant } from '../../model/Core';
import { Link } from 'react-router-dom';
import { FLAGS } from '../../config';
import { Select } from '../Common';
import LogoutButton from '../LogoutButton';
import ToggleDarkMode from '../ToggleDarkMode';
import { useAuth } from '../../hooks/queries';
import useFlags from '../../hooks/flags';
import logo from '../../media/logo.jpg';
import styles from './styles.module.scss';

type BannerProps = {
  tenants?: DataSet;
  onTenantChange?: (tenant: Tenant) => void;
};

const Banner: FC<BannerProps> = ({
  tenants,
  onTenantChange,
}) => {
  const { hasFeature } = useFlags();
  const { user } = useAuth();

  return (
    <section className={styles.banner}>
      <Link className={styles.logo} to="/">
        <img className={styles.img} src={logo} alt="ABES Home" />
      </Link>

      {hasFeature(FLAGS.darkMode) && <ToggleDarkMode />}

      <div className={styles.user}>
        {tenants && Object.keys(tenants).length && onTenantChange && (
          <Select
            className={styles.tenants}
            label="Tenant"
            options={Object.values(tenants).map((tenant) => ({ value: tenant.id, label: tenant.name }))}
            onChange={(value) => onTenantChange(tenants[value])}
          />
        )}
        {(!tenants || Object.keys(tenants).length === 0) && (
          <div className={styles.account}>Tenant: {user.tenantId}</div>
        )}
        <LogoutButton className={styles.signout}>Sign out</LogoutButton>
      </div>
    </section>
  );
};

export default Banner;
