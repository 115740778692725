import { Vector3 } from 'three';
import { toRel, sysToRel, acosLtd } from '../maths';
import { computeDiscr, computeMove, getStdAlerts, getDistMeanMax } from './utils';

export const computeConj = (cps, sys, settings): {
  info: {
    max: number;
    mean: number;
    abs: number;
    phiMax: number;
  };
  discr: ComputedError[];
  move: ComputedError[];
} => {
  const sysRel = sysToRel(sys.post, sys.pre);

  const { discr, move } = cps.reduce((computed, { pre, post, name }) => {
    const posPre = toRel(pre, sys.pre);
    const posPost = toRel(post, sys.post);

    const discr = computeDiscr(posPre, posPost);
    const move = computeMove(pre, sysRel);

    return {
      discr: computed.discr.concat({
        name,
        values: {
          dist: discr.length(),
          x: discr.x,
          y: discr.y,
          z: discr.z,
        },
      }),
      move: computed.move.concat({
        name,
        values: {
          dist: move.length(),
          x: move.x,
          y: move.y,
          z: move.z,
        }
      }),
    };
  }, { discr: [], move: [] });

  const discrAlerts = getStdAlerts(discr, settings.discrSecConj, settings.factorWarn);
  const moveAlerts = getStdAlerts(move, settings.rigidSecConj, settings.factorWarn);
  const displace = sys.post.x.clone().sub(sys.pre.x);
  const crossX = sysRel.y.clone().cross(sysRel.z);
  const phiDx = acosLtd(crossX.dot(new Vector3(1, 0, 0))) * 180.0 / Math.PI;
  const phiDy = acosLtd(sysRel.y.dot(new Vector3(0, 1, 0))) * 180.0 / Math.PI;
  const phiDz = acosLtd(sysRel.z.dot(new Vector3(0, 0, 1))) * 180.0 / Math.PI;

  return {
    info: {
      ...getDistMeanMax(discr),
      abs: displace.length(),
      phiMax: Math.max(phiDx, phiDy, phiDz),
    },
    discr: discr.map((error, i) => ({ ...error, alerts: discrAlerts[i] })),
    move: move.map((error, i) => ({ ...error, alerts: moveAlerts[i] })),
  };
};
