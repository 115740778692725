import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRefreshToken } from '../../hooks/queries';
import Loader from '../../components/Loader';

export const PrivateRoute = () => {
  const { isPending, error } = useRefreshToken();
  const location = useLocation();

  if (isPending) return <Loader />;

  return error
    ? <Navigate to="/login" state={{ from: location }} replace />
    : <Outlet />;
};

export const PublicRoute = () => {
  const { isPending, error } = useRefreshToken();
  const location = useLocation();

  if (isPending) return <Loader />;
  if (error) return <Outlet />;

  return <Navigate to="/" state={{ from: location }} replace />;
};
