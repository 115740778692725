import type { Tenant } from '../../model/Core';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTenants, useProjects, useDeleteProject } from '../../hooks/queries';
import Loader from '../../components/Loader';
import Banner from '../../components/Banner';
import ProjectList from '../../components/ProjectList';
import styles from './styles.module.scss';

const MainLayout = () => {
  const [tenant, setTenant] = useState<Tenant>();
  const navigate = useNavigate();

  const { data: tenants } = useTenants();
  const { mutate: handleDeleteProject } = useDeleteProject(tenant?.id);
  const { isPending, isError, data: projects } = useProjects(tenant?.id);

  const handleTenantChange = (tenant: Tenant) => {
    setTenant(tenant);
    navigate('/');
  };

  return (
    <div className={styles.layout}>
      <Banner tenants={tenants} onTenantChange={handleTenantChange}/>

      <div className={styles.main}>
        <div className={styles.sidebar}>
          {isPending && <Loader />}
          {!isPending && !isError && (
            <ProjectList
              className={styles.sidebarContent}
              itemClassName={styles.sidebarContentItem}
              projects={projects}
              deletable={Boolean(tenants)}
              onDelete={(projectId) => handleDeleteProject(projectId)}
            />
          )}
          {!isPending && isError && (
            <div>Access denied</div>
          )}
        </div>

        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
