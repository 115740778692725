import type { FC } from 'react';
import type { Sequence } from '../../model/Core';
import SequenceCard from '../SequenceCard';
import styles from './styles.module.scss';

type SequenceListProps = {
  sequences: Sequence[];
};

const SequenceList: FC<SequenceListProps> = ({ sequences }) => {
  return (
    <ul>
      {sequences.map((sequence) => (
        <li key={sequence.id} className={styles.sequenceItem}>
          <SequenceCard
            {...sequence}
            segments={sequence.segments}
            report={sequence.report}
            reportEntries={Object.values(sequence.reportEntries)}
          />
        </li>
      ))}
    </ul>
  );
};

export default SequenceList;
