import type { FC } from 'react';
import type { SegmentData } from '../../model/Core';
import styles from './styles.module.scss';

type SegmentTitleProps = {
  segment: SegmentData
};

const SegmentTitle: FC<SegmentTitleProps> = ({
  segment,
}) => {
  return (
    <h2 className={styles.title}>
      {segment.name}
      {' - '}
      <span className={styles.sub}>({segment.type})</span>
    </h2>
  );
};

export default SegmentTitle;
