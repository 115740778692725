import type { FC, ReactNode, ReactElement } from 'react';
import { useState, cloneElement, isValidElement, Children } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

type DropdownProps = {
  label: ReactNode;
  children: ReactNode;
  className?: string;
};

const Dropdown: FC<DropdownProps> = ({
  label,
  children,
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={classnames(styles.dropdown, {
      [className]: !!className,
    })}>
      <button
        className={styles.label}
        onClick={() => setIsOpen(!isOpen)}
      >{label}</button>
      {isOpen && children && (
        <ul className={styles.items}>
          {Children.toArray(children).filter(isValidElement).map((elem, index) => (
            <li key={index} className={styles.item}>{
              cloneElement(elem as ReactElement<any>, {
                className: classnames(styles.itemChild, {
                  [elem.props.className]: !!elem.props.className,
                }),
                onClick: () => {
                  if (elem.props.onClick) {
                    elem.props.onClick();
                  }
                  setIsOpen(false);
                },
              })
            }</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
