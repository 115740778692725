import { Routes, Route } from 'react-router-dom';
import { initFeatureFlags } from './flags';
import MainLayout from './layouts/MainLayout';
import { PrivateRoute, PublicRoute } from './layouts/RequireAuth';
import Dashboard from './routes/Dashboard';
import Sequences from './routes/Sequences';
import Segment from './routes/Segment';
import Login from './routes/Login';

initFeatureFlags();

const App = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/project/:projectId" element={<Sequences />} />
          <Route path="/project/:projectId/:segmentId" element={<Segment />} />
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default App;
