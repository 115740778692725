import type { Auth } from '../../../model/Auth';

type AuthDto = {
  accessToken: string;
  user: {
    email: string;
    tenantId: string;
  };
};

const toAuth = ({ accessToken, user }: AuthDto): Auth => ({
  isSignedIn: !!accessToken,
  accessToken,
  user,
});

const AuthRepository = {
  getToken(code: string, redirectUrl: string) {
    return fetch('/api/auth/token', {
      method: 'POST',
      body: JSON.stringify({
        code,
        redirectUrl,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then(toAuth);
  },
  clearToken(accessToken: string) {
    return fetch('/api/auth/signout', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return {};
        } else {
          throw new Error();
        }
      });
  },
  refreshToken(): Promise<Auth> {
    return fetch('/api/auth/refresh')
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error();
        }
      })
      .then(toAuth);
  },
};

export const {
  getToken,
  clearToken,
  refreshToken,
} = AuthRepository;

export default AuthRepository;
