// Move to cookies if passing those flags to the backend is necessary
import type { Flags } from '../flags';
import { useEffect, useState } from 'react';
import { STORAGE } from '../config';

const useFlags = () => {
  const [flags, setFlags] = useState<Flags>({} as Flags);

  const getLocalState = () =>
    JSON.parse(localStorage.getItem(STORAGE.featureFlags) || '{}');

  useEffect(() => {
    const storageChangeHandler = () => setFlags(getLocalState);

    setFlags(getLocalState);
    window.addEventListener('flagChange', storageChangeHandler);

    return () => {
      window.removeEventListener('flagChange', storageChangeHandler);
    };
  }, []);

  const hasFeature = (flag: keyof Flags): boolean => flags[flag];

  return {
    hasFeature,
  };
};

export default useFlags;
