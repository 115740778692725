import type { FC } from 'react';
import styles from './styles.module.scss';

type HelpfulGuideProps = {
  message: string;
};

const HelpfulGuide: FC<HelpfulGuideProps> = ({
  message,
}) => {
  return (
    <div className={styles.guide}>
      {message}
    </div>
  );
};

export default HelpfulGuide;
