import type { FC } from 'react';
import type { Sequence, SegmentHeader, Report, ReportEntry } from '../../model/Core';
import { Link } from 'react-router-dom';
import { useTenants, useDeleteSequence } from '../../hooks/queries';
import { SvgImage, Dropdown } from '../Common';
import { toDateString } from '../../utils/date';
import styles from './styles.module.scss';

type SequenceCardProps = Omit<Sequence, 'reportEntries'> & {
  segments: SegmentHeader[];
  report: Report;
  reportEntries: ReportEntry[];
};

const SequenceCard: FC<SequenceCardProps> = ({
  projectId,
  id,
  date,
  name,
  segments,
  report,
  reportEntries,
  dump,
}) => {
  const { data: tenants } = useTenants();
  const { mutate: handleDeleteSequence } = useDeleteSequence();

  const xmlUrl = URL.createObjectURL(new Blob([dump], { type: 'text/xml' }));

  return (
    <div className={styles.sequenceCard}>
      <div className={styles.header}>
        <div className={styles.title}>{name}</div>
        <div className={styles.date}>{toDateString(date)}</div>
        <Dropdown
          className={styles.extra}
          label={<SvgImage className={styles.extraLabel} reference="more" />}
        >
          <a download={`${name}.xml`} href={xmlUrl}>
            <SvgImage reference="download" />  Download
          </a>
          {Boolean(tenants) && (
            <button className={styles.delete} onClick={() => handleDeleteSequence({ projectId, sequenceId: id })}>
              <SvgImage reference="delete" />  Delete
            </button>
          )}
        </Dropdown>
      </div>
      <div className={styles.report}>
        <div className={styles.reportEntries}>
          {reportEntries.map((entry) => (
            <div key={entry.id}>
              <span className={styles.label}>{entry.type === 'cast' ? 'Casting Crew' : 'Survey Team'}:</span>
              {' '}
              <span>{entry.name}</span>
            </div>
          ))}
        </div>
        <div>
          <span className={styles.label}>Environment:</span>
          {', '}
          <span>{report.temperature}C</span>
          {', '}
          <span>{report.humidity}pC</span>
          {', '}
          <span>{report.pressure}hPA</span>
        </div>
      </div>
      <ul className={styles.footer}>
        {segments.map((segment) => (
          <li key={segment.id} className={styles.listItem}>
            <Link
              className={styles.linkItem}
              to={`/project/${projectId}/${segment.id}`}
            >
              {segment.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SequenceCard;
